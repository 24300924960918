import React from 'react';

const About = () => {
  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-3xl font-bold mb-6">
        About CarDoor
      </h1>
      <p className="mb-6">
        CarDoor is a leading car rental company in Canada, offering a wide range of vehicles to meet all of your transportation needs. Whether you're looking for a compact car for city driving, a spacious SUV for a family vacation, or a luxury vehicle for a special occasion, we have you covered.
      </p>
      <h2 className="text-2xl font-bold mb-4">
        Our Mission
      </h2>
      <p className="mb-6">
        Our mission is to provide our customers with the best possible car rental experience, from start to finish. We strive to offer competitive rates, a wide selection of vehicles, and exceptional customer service, so that you can focus on enjoying your trip.
      </p>
      <h2 className="text-2xl font-bold mb-4">
        Our Values
      </h2>
      <ul className="list-disc list-inside mb-6">
        <li className="mb-2">
          <strong>Integrity:</strong> We are committed to honesty and transparency in all of our dealings with customers, partners, and employees.
        </li>
        <li className="mb-2">
          <strong>Customer Focus:</strong> We put our customers first, and strive to exceed their expectations in every interaction.
        </li>
        <li className="mb-2">
          <strong>Innovation:</strong> We are constantly looking for ways to improve our services and offerings, and to stay at the forefront of the car rental industry.
        </li>
        <li className="mb-2">
          <strong>Sustainability:</strong> We are committed to reducing our environmental impact, and to promoting sustainable transportation solutions.
        </li>
      </ul>
      <p className="mb-6">
        Thank you for choosing CarDoor. We look forward to serving you!
      </p>
    </div>
  );
};

export default About;
import React from 'react';
import { Link } from 'react-router-dom';
import FinancialInquiryForm from '../components/Section/FinancialInquiryForm';
import bannerimg from '../images/banner.jpg'
import FinanceGlo from '../components/Global/Finance';
import Button from '../components/Button';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import Form from '../components/Finance/Form';

const CarFinanceCalculator = () => {
    return (
        <section>
            <div className="hero bg-gray-200">
                <div className="container mx-auto px-4 py-16">
                    <h1 className="text-5xl font-bold text-center mb-1">Car Loan Calculator</h1>
                    <p className="subtitle text-center text-lg text-gray-600">Easily estimate your budget for your dream car</p>
                    <form>
                    <div className="md:w-10/12 mx-auto flex flex-col lg:flex-row justify-between items-center lg:justify-center pt-4">
                        <div className="w-full lg:w-6/12 lg:mx-4 border bg-white rounded-2xl shadow-inner drop-shadow-lg">
                            <Form/>
                        </div>
                        <div className="w-full lg:w-6/12 lg:mr-8 border h-32 bg-white rounded-2xl shadow-inner drop-shadow-lg">
                            
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            <div className='container md:w-8/12 mx-auto text-center py-8'>
                    <h2 className="md:text-4xl my-8 text-xl font-semibold mx-auto">Get Financing through zoom cars</h2>
                <FinanceGlo />
            </div>
            <div className="py-16 flex flex-col bg-blue-500">
                <div className='container md:w-8/12 mx-auto text-center'>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center rounded-2xl bg-white drop-shadow-2xl'>
                        <div className='flex flex-col flex-1'>
                            <img className='lg:m-0 m-4 bg-no-repeat bg-cover bg-center rounded-2xl my-auto' src={bannerimg} />
                        </div>
                        <div className='flex flex-col flex-1 text-left h-full my-auto lg:pt-0 pt-8'>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="md:text-4xl mb-4 text-lg font-semibold">Not sure of your budget?</h2>
                                <p className='mb-4'>Check out our loan calculator to estimate your monthly or biweekly payments.</p>
                                <Link to="/financecal">
                                    <div className=' text-center'>
                                        <Button>View Calculator</Button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-16 flex flex-col">
                <div className='container md:w-8/12 mx-auto text-left'>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center rounded-xl'>
                        <div className='flex flex-col text-left w-2/3 h-full my-auto lg:pt-0 pt-8'>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="md:text-4xl mb-4 text-lg font-semibold">Financing made easy</h2>
                                <p className='mb-4'>At Zoom Cars, we make financing your next car a breeze. Simply pick out a car you like and select 'Financing' during checkout. We'll reach out to our financial partners to get you a competitive rate.</p>
                                <Link to="/financecal">
                                    <div className=' text-center'>
                                        <Button>Get pre-qualified</Button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='flex flex-col w-1/3'>
                            <div className='shadow shadow-blue-500 rounded-3xl p-8'>
                                <h2 className="md:text-2xl mb-2 text-lg font-semibold">Great news 🎉 <br />You're pre-qualified for up to</h2>
                                <p className='mb-4 md:text-4xl font-semibold '>$38,000<span className=' font-normal md:text-xl'>($210 biweekly)</span></p>
                            </div>
                            <div className='shadow shadow-blue-500 rounded-3xl mt-4 p-8'>
                                <h1 className='flex text-xl font-semibold'>
                                    <div className='bg-blue-500 text-white rounded-full w-8 text-center h-8 mr-2 pt-0.5'>1</div>Shop cars in your budget</h1>
                                <p className='pl-10 pt-0.5'>Browse hundreds of high-quality cars to find the one you love.</p>
                                <h1 className='flex text-xl font-semibold pt-4'>
                                    <div className='bg-blue-500 text-white rounded-full w-8 text-center h-8 mr-2 pt-0.5'>2</div>Get it delivered to your door</h1>
                                <p className='pl-10 pt-0.5'>Complete your order online and we'll bring it to your door in days.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CarFinanceCalculator;
import React from 'react'

const Button = (props) => {
  return (
    <button className='bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
    duration-500'>
      {props.children}
    </button>
  )
}

export default Button
import React, { Component } from 'react'
import Button from '../Button'

export class Form extends Component {
    render() {
        return (
            <div className='text-left px-4 my-4'>
                <h1 className="text-2xl font-bold my-2">Submit a question</h1>
                <h1 className='text-md'>Submit a question through our contact form below and we'll get back to you as soon as possible.</h1>
                <div className="flex justify-center">
                    <div className="w-full">
                        <form className="bg-white rounded pt-6 pb-8 mb-4">
                            <div className="mb-4">
                                <label className="block text-gray-500 text-lg font-bold mb-2" htmlFor="name">
                                    First Name
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                                    id="name"
                                    type="text"
                                    placeholder="Your Name"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-500 text-sm font-bold mb-2" htmlFor="name">
                                    Last Name
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                                    id="name"
                                    type="text"
                                    placeholder="Your Name"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-500 text-sm font-bold mb-2" htmlFor="name">
                                    Email
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                                    id="name"
                                    type="text"
                                    placeholder="Your Name"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-500 text-sm font-bold mb-2" htmlFor="email">
                                    What can We help you with?
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="email"
                                    placeholder="Your Email"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-500 text-sm font-bold mb-2" htmlFor="message">
                                    Message
                                </label>
                                <textarea
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                                    id="message"
                                    rows="5"
                                    placeholder="Your Message"
                                ></textarea>
                            </div>
                            <div className="flex items-center justify-between">
                                <Button>
                                    Send Message
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Heroimg from '../images/Hero.jpg';
import Marquee from 'react-fast-marquee';

const Hero = () => {
  return (
    <section className="hero bg-cover bg-top bg-no-repeat rounded-b-3xl" style={{ backgroundImage: `url(${Heroimg})` }}>
      <div className="container mx-auto px-4 py-48">
        <div className='bg-black text-white w-1/3 p-4 rounded-xl bg-opacity-50'>
          <Marquee><h1 className="text-5xl font-bold mb-4">Click, Buy, Drive</h1></Marquee>
          <p className="subtitle text-2xl">Your Car, Your Terms.</p>
          </div>
          <div className="container pt-8 mx-auto flex flex-col lg:flex-row items-center">
            <Link to="/finance">
              <div className=" flex flex-row items-center md:items-start mb-10 lg:mb-0 bg-blue-500 text-white p-8 rounded-xl hover:bg-white hover:text-blue-500">
                <div className='flex flex-col'>
                  <h2 className="text-2xl font-semibold mb-2">Get Approved Today!</h2>
                  <p className="hidden md:block text-md">Find your perfect car at the best price.</p>
                </div>
                <div className="flex pl-6 my-auto">
                  <div className=" bg-white text-blue-500 hover:bg-blue-500 hover:text-white px-3 py-2 rounded-3xl text-2xl"><FontAwesomeIcon icon={faArrowRight} /></div>
                </div>
              </div>
            </Link>
            {/* <Link to="/cars">
            <div className="w-auto flex flex-row items-center md:items-start mb-10 lg:mb-0 bg-sky-200 text-sky-800 p-8 rounded-xl">
              <div className='flex flex-col'>
                <h2 className="text-2xl font-semibold mb-2">Shop Cars</h2>
                <p className="hidden md:block text-md">Find your perfect car at the best price.</p>
              </div>
              <div className="flex pl-6 my-auto">
                <div className=" bg-white text-blue-500 px-3 py-2 rounded-3xl text-2xl"><FontAwesomeIcon icon={faArrowRight} /></div>
              </div>
            </div>
          </Link>
          <Link to="/Sell">
            <div className="w-auto flex flex-row items-center md:items-start mb-10 lg:mb-0 bg-sky-200 text-sky-800 p-8 rounded-xl">
              <div className='flex flex-col'>
                <h2 className="text-2xl font-semibold mb-2">Sell / Trade Car</h2>
                <p className="hidden md:block text-md">Find your perfect car at the best price.</p>
              </div>
              <div className="flex pl-6 my-auto">
                <div className=" bg-white text-blue-500 px-3 py-2 rounded-3xl text-2xl"><FontAwesomeIcon icon={faArrowRight} /></div>
              </div>
            </div>
          </Link> */}
          </div>
        
      </div>
    </section>
  );
};

export default Hero;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faHandHoldingDollar, faSackDollar } from '@fortawesome/free-solid-svg-icons';

class Finance extends Component {
    
    render() {
        return (
            
                    <div className='flex my-8'>
                        <div className='border-2 border-blue-500 shadow-lg shadow-gray-400 hover:shadow-inner hover:shadow-blue-500 rounded-xl flex-col mx-2 p-8 text-center w-1/3'>
                            <FontAwesomeIcon icon={faClock} className='text-4xl text-blue-500'/>
                            <h2 className='text-xl font-semibold py-2'>Save Time</h2>
                            <p>Don't spend your valuable time traveling between dealerships and browsing vehicles without certainty of approval. We prioritize getting you pre-approved upfront, enabling you to shop with confidence, knowing precisely what fits your budget.</p>
                        </div>
                        <div className='border-2 border-blue-500 shadow-lg shadow-gray-400 hover:shadow-inner hover:shadow-blue-500 rounded-xl flex-col mx-2 p-8 w-1/3'>
                            <FontAwesomeIcon icon={faHandHoldingDollar} className='text-4xl text-blue-500'/>
                            <h2 className='text-xl font-semibold py-2'>Stress-Free Experience</h2>
                            <p>Encountering a car you adore, only to realize you can't secure a loan for it, can be disheartening. However, by obtaining pre-approval beforehand, we eliminate all the stress from car shopping. Say goodbye to the anxiety of potential rejection!</p>
                        </div>
                        <div className='border-2 border-blue-500 shadow-lg shadow-gray-400 hover:shadow-inner hover:shadow-blue-500 rounded-xl flex-col mx-2 p-8 w-1/3'>
                            <FontAwesomeIcon icon={faSackDollar} className='text-4xl text-blue-500'/>
                            <h2 className='text-xl font-semibold py-2'>Hassle-Free Experience</h2>
                            <p>Discovering a car you love, only to face the disappointment of loan rejection, can be disheartening. But with pre-approval, we eradicate all the stress from your car shopping journey. Bid farewell to the worry of potential rejection!</p>
                        </div>
                    </div>

            
        )
    }
}

export default Finance
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import bannerimg from '../../images/securefinance.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClipboardCheck, faDollarSign, faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import Button from '../Button';

class About extends Component {
    
    render() {
        return (
            <div className="pt-16 flex flex-col">
                <div className='container md:w-8/12 mx-auto text-center border-2 rounded-xl'>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center bg-white rounded-xl'>
                        <div className='flex-col flex-1'>
                            <img className='lg:m-0 m-4 bg-no-repeat bg-cover bg-center rounded-2xl my-auto' src={bannerimg} />
                        </div>
                        <div className='flex flex-col flex-1 text-left h-full my-auto lg:pt-0 pt-8'>
                            <div className='mx-auto w-5/6 mb-4'>
                                <h2 className="md:text-2xl mb-4 text-lg font-semibold text-blue-500">Secure Financing for Your Next Car</h2>
                                <p className='mb-4'>We'll discover the most competitive rates and ensure your approval within minutes.</p>
                                <div className='text-center'>
                                <Button>Learn More</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='md:w-8/12 mx-auto pt-16'>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center bg-blue-500 border-2 rounded-2xl'>
                        <div className='flex flex-col flex-1'>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="md:text-5xl mb-4 text-lg font-semibold text-white">Buy with Confidence</h2>
                            </div>
                            <div className='mx-auto w-4/5 mb-4 flex flex-row'>
                                <FontAwesomeIcon className=" bg-white text-blue-500 rounded-full text-4xl my-auto py-3 w-16 z-40 shadow-black shadow-md" icon={faDollarSign} />
                                <div className="md:text-2xl text-lg font-semibold my-auto pr-5 py-1 -ml-5 pl-8 z-30 rounded-xl bg-white text-blue-500">Low Interest Rates</div>
                            </div>
                            <div className='mx-auto w-4/5 mb-4 flex flex-row'>
                                <FontAwesomeIcon className=" bg-white text-blue-500 rounded-full text-4xl my-auto py-3 w-16 z-40 shadow-black shadow-md" icon={faClipboardCheck} />
                                <div className="md:text-2xl text-lg font-semibold my-auto pr-5 py-1 -ml-5 pl-8 z-30 rounded-xl bg-white text-blue-500">All Credit Approved</div>
                            </div>
                        </div>
                        <div className='flex flex-col flex-1 text-left h-full my-auto p-8 rounded-xl bg-white'>
                            <p className="md:text-xl mb-4 text-lg text-justify">Buying a used car can be stressful, complicated, and time consuming. We get it. We’ve been there. And that’s exactly why we created ZoomCars. We believe that car shopping should fit around your life, and not the other way around</p>
                            <ul>
                                <li className="md:text-lg mb-4 text-md text-justify"><FontAwesomeIcon className='text-blue-500' icon={faCircleCheck} /> Convenient, online car-buying experience</li>
                                <li className="md:text-lg mb-4 text-md text-justify"><FontAwesomeIcon className='text-blue-500' icon={faCircleCheck} /> Car enthusiasts to accompany you through the journey</li>
                                <li className="md:text-lg mb-4 text-md text-justify"><FontAwesomeIcon className='text-blue-500' icon={faCircleCheck} /> Highest customer satisfaction</li>
                            </ul>
                            <div className='mx-auto'>
                                <Button>Learn more</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default About;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import bannerimg from '../../images/getapproved.jpg';
import Button from '../Button';

class Buy extends Component {
    
    render() {
        return (
            <div className="pt-16 flex flex-col">
                    <h2 className="text-4xl font-bold mb-8 mx-auto">An Easier Way to <span className='text-blue-500'>Get a Car</span></h2>
                <div className='md:w-8/12 mx-auto text-center border-2 rounded-2xl'>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center bg-white rounded-xl'>
                        <div className='flex flex-col flex-1'>
                            <img className='lg:m-0 m-4 bg-no-repeat bg-cover bg-center rounded-2xl my-auto' src={bannerimg} />
                        </div>
                        <div className='flex flex-col flex-1 text-left h-full my-auto lg:pt-0 pt-8'>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="md:text-xl mb-4 text-lg font-semibold">Zoom Cars can assist you in securing vehicle financing, regardless of your credit score.</h2>
                                <p className='mb-4 text-justify'>Most dealers only work with one or two lenders.  We have access to dozens of lenders that will compete to give you the <b className='text-blue-500'>BEST RATE!</b>  Even if you've been declined for a car loan we can help you out.  Stop wasting time and applying at multiple dealerships.</p>
                                <p className='mb-4'>Every time you apply for a loan your credit score suffers.  Come to the experts first and we will get you <span className='text-blue-500 font-semibold'>approved!</span></p>
                                <div className='text-center'>
                                    <Button>Get Approved Today</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default Buy
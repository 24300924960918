import React from 'react';
import { Link } from 'react-router-dom';
import FinancialInquiryForm from '../components/Section/FinancialInquiryForm';
import banner from '../images/FinanceHero.jpg'
import budget from '../images/budget.jpg'
import FinanceGlo from '../components/Global/Finance';
import Button from '../components/Button';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
const Finance = () => {
  return (
    <section>
      <div className="hero bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${banner})` }}>
        <div className="container mx-auto py-32">
          <h1 className="text-5xl text-left font-bold mb-8">Finance your <span className='text-blue-500'>next car</span> with ease<br />simpler than you imagine</h1>
          <p className="subtitle text-left text-2xl text-white drop-shadow-2xl">Get <span className='text-blue-500'>pre-qualified</span> within minutes and begin benefiting from easy and<br /> affordable payments on your new vehicle.</p>
          <div className="mx-auto flex flex-col lg:flex-row  ">
            <Link to="/financeform">
              <div className="w-full flex flex-row items-center md:items-start mb-10 lg:mb-0 mt-8 bg-blue-500 text-white hover:bg-white hover:text-blue-500 p-8 rounded-xl">
                <div className='flex flex-col'>
                  <h2 className="text-2xl font-semibold mb-2">Get Approved Today!</h2>
                  <p className="hidden md:block text-md">Find your perfect car at the best price.</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="py-8 flex flex-col">
        <div className='container md:w-8/12 mx-auto text-center'>
          <FinanceGlo />
        </div>
      </div>
      <div className="py-16 flex flex-col bg-blue-500">
        <div className='container md:w-8/12 mx-auto text-center'>
          <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center rounded-2xl bg-white drop-shadow-2xl'>
            <div className='flex flex-col flex-1'>
              <img className='lg:m-0 m-4 bg-no-repeat bg-cover bg-center rounded-2xl my-auto' src={budget} />
            </div>
            <div className='flex flex-col flex-1 text-left h-full my-auto lg:pt-0 pt-8'>
              <div className='mx-auto w-4/5 mb-4'>
                <h2 className="md:text-4xl mb-4 text-lg font-semibold">Unsure about your budget?</h2>
                <p className='mb-4'>Utilize our loan calculator to estimate your monthly or biweekly payments.</p>
                <Link to="/financecal">
                  <div className=' text-center'>
                    <Button>View Calculator</Button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-16 flex flex-col">
        <div className='container md:w-8/12 mx-auto text-left'>
          <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center rounded-xl'>
            <div className='flex flex-col text-left w-2/3 h-full my-auto lg:pt-0 pt-8'>
              <div className='mx-auto w-4/5 mb-4'>
                <h2 className="md:text-4xl mb-4 text-lg font-semibold">Financing made easy</h2>
                <p className='mb-4'>At Zoom Cars, we make financing your next car a breeze. Complete our straightforward online financing application.
                  We'll connect you with a nearby dealership where you'll explore vehicle choices already pre-approved for you.
                  Select your desired car and drive off! No more time wasted. No more worries about rejection.</p>
                <Link to="/financecal">
                  <div className=' text-center'>
                    <Button>Get pre-qualified</Button>
                  </div>
                </Link>
              </div>
            </div>
            <div className='flex flex-col w-1/3'>
              <div className='shadow shadow-blue-500 rounded-3xl p-8'>
                <h2 className="md:text-2xl mb-2 text-lg font-semibold">Great news 🎉 <br />You're pre-qualified for up to</h2>
                <p className='mb-4 md:text-4xl font-semibold '>$48,000<span className=' font-normal md:text-xl'>($310 biweekly)</span></p>
              </div>
              <div className='shadow shadow-blue-500 rounded-3xl mt-4 p-8'>
                <h1 className='flex text-xl font-semibold'>
                  <div className='bg-blue-500 text-white rounded-full w-8 text-center h-8 mr-2 pt-0.5'>1</div>Shop cars in your budget</h1>
                <p className='pl-10 pt-0.5'>Browse hundreds of high-quality cars to find the one you love.</p>
                <h1 className='flex text-xl font-semibold pt-4'>
                  <div className='bg-blue-500 text-white rounded-full w-8 text-center h-8 mr-2 pt-0.5'>2</div>We will get you approved</h1>
                <p className='pl-10 pt-0.5'>Complete the form online and we'll get you your car in days.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Finance;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import bannerimg from '../../images/banner.jpg';
import Button from '../Button';

class Hero extends Component {
    
    render() {
        return (
            <div className="pt-12 flex flex-col">
                <div className='md:w-10/12 mx-auto text-center'>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center border border-blue-200 shadow-2xl rounded-2xl'>
                        <div className='flex flex-col flex-1 text-left h-full my-auto lg:pt-0 pt-8'>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="mb-4 text-lg text-gray-600 font-semibold">CONTACT US</h2>
                                <h2 className="mb-4 text-4xl font-bold">Got a question? We're here to help.</h2>
                            </div>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <img className='lg:m-0 m-4 bg-no-repeat bg-cover bg-center rounded-2xl my-auto' src={bannerimg} />
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default Hero
import React, { useState } from 'react';

const Form = () => {
  const [vehiclePrice, setVehiclePrice] = useState(0);
  const [downPayment, setDownPayment] = useState('');
  const [tradeInValue, setTradeInValue] = useState('');
  const [termLength, setTermLength] = useState('');
  const [creditScore, setCreditScore] = useState('');
  const [biweeklyPayment, setBiweeklyPayment] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState('');

  const handleSliderChange = (event) => {
    setVehiclePrice(event.target.value);
  };

  const handleDownPaymentChange = (event) => {
    setDownPayment(event.target.value);
  };

  const handleTradeInValueChange = (event) => {
    setTradeInValue(event.target.value);
  };

  const handleTermLengthChange = (event) => {
    setTermLength(event.target.value);
  };

  const handleCreditScoreChange = (event) => {
    setCreditScore(event.target.value);
  };

  return (
    <form className='m-8'>
      <label>
        Vehicle Price:
        <span className='absolute ml-2'>$</span>
        <input
          type="text"
          min="0"
          max="100000"
          value= {vehiclePrice.toLocaleString()}
          onChange={handleSliderChange}
          className='px-6'
        />
        <input
          type="range"
          min="0"
          max="100000"
          value={vehiclePrice}
          onChange={handleSliderChange}
        />
      </label>
      <br />
      <label>
        Down Payment:
        <input
          type="text"
          value={downPayment}
          onChange={handleDownPaymentChange}
          placeholder="$0"
        />
      </label>
      <br />
      <label>
        Trade-In Value:
        <input
          type="text"
          value={tradeInValue}
          onChange={handleTradeInValueChange}
          placeholder="$0"
        />
      </label>
      <br />
      <label>
        Term Length:
        <select value={termLength} onChange={handleTermLengthChange}>
          <option value="">Select</option>
          <option value="36">36 months</option>
          <option value="48">48 months</option>
          <option value="60">60 months</option>
          <option value="72">72 months</option>
        </select>
      </label>
      <br />
      <label>
        Credit Score:
        <select value={creditScore} onChange={handleCreditScoreChange}>
          <option value="">Select</option>
          <option value="600">600+</option>
          <option value="650">650+</option>
          <option value="700">700+</option>
          <option value="750">750+</option>
        </select>
      </label>
      <br />
      <button type="submit">Get Pre-Qualified</button>
    </form>
  );
};

export default Form;
import React from 'react'
import Hero from '../components/Hero'
import Banner from '../components/Global/Banner';
import Finance from '../components/Global/Finance';
import Reviews from '../components/Global/Reviews';
import About from '../components/Global/About';
import Buy from '../components/Global/Buy';

const Home = () => {
  return (
    <div className="bg-white pb-24">
      <Hero/>
      <Buy/>
      <Banner/>
      <About/>
    </div>
  );
};

export default Home;
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';

function Info() {
  return (
    <div className='flex flex-col'>
        <div className='w-4/5 mx-auto rounded-xl py-8 mb-4'>
            <h1 className='text-2xl font-semibold'>Call or text us</h1>
            <p className='text-md text-justify py-4 '>Our Customer Support team is available via telephone 7 days a week. You can also check out our FAQ Page for more information.</p>
            <p className='text-md text-justify py-1 font-semibold'>Monday - Friday: <span className=' font-normal'>9am - 9pm EDT</span></p>
            <p className='text-md text-justify py-1 font-semibold'>Saturday & Sunday: <span className=' font-normal'>10am - 6pm EDT</span></p>
            <div className='mt-2'>
                <Button>(226) 977-0496</Button>
            </div>
        </div>
        <div className='w-4/5 mx-auto rounded-xl py-8 mt-4'>
            <h1 className='text-2xl font-semibold'>Call or text us</h1>
            <p className='text-md text-justify py-4 '>Our Customer Support team is available via telephone 7 days a week. You can also check out our FAQ Page for more information.</p>
            <p className='text-md text-justify py-1 font-semibold'>Monday - Friday: <span className=' font-normal'>9am - 9pm EDT</span></p>
            <p className='text-md text-justify py-1 font-semibold'>Saturday & Sunday: <span className=' font-normal'>10am - 6pm EDT</span></p>
            <div className='mt-2'>
                <Button>(226) 977-0496</Button>
            </div>
        </div>
    </div>
  )
}

export default Info
import React, { useState } from 'react';
import './FinanceForm.css';
import car from '../../images/Car.png';
import truck from '../../images/Truck.png';
import suv from '../../images/SUV.png';
import van from '../../images/Van.png';


const FinanceForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isShown, setIsShown] = useState(false);
  const [isShownLong, setIsShownLong] = useState(false);
  const [vehicleType, setVehicleType] = useState('');
  const [creditScore, setCreditScore] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [budget, setBudget] = useState('');
  const [tradeIn, setTradeIn] = useState('');
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [monthlyIncome, setMonthlyIncome] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [empDur, setEmpDur] = useState({ year: "", month: "" });
  const [employmentDuration, setEmploymentDuration] = useState('');
  const [homeAddress, setHomeAddress] = useState({ address: "", city: "", province: "", postalCode: "" });
  const [duration, setDuration] = useState('');
  const [isOwner, setIsOwner] = useState('');
  const [houseRent, setHoueRent] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [name, setName] = useState({ fname: "", lname: "" });
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('empty')

  const handleVisibility = (event) => {
    if (event.target.value == 0) {
      setIsShown(true);
      setIsShownLong(false);
      setEmploymentDuration(event.target.value);
    }
    else if (event.target.value == 1) {
      setIsShownLong(true);
      setIsShown(false);
      setEmploymentDuration(event.target.value);
    }
  }

  const handleVehicleTypeChange = (event) => {
    setVehicleType(event.target.value);
  }

  const handleCreditScoreChange = (event) => {
    setCreditScore(event.target.value);
  }

  const handleDownPaymentChange = (event) => {
    setDownPayment(event.target.value);
  }

  const handleBudgetChange = (event) => {
    setBudget(event.target.value);
  }

  const handleTradeInChange = (event) => {
    setTradeIn(event.target.value);
  }

  const handleEmploymentStatusChange = (event) => {
    setEmploymentStatus(event.target.value);
  }

  const handleMonthlyIncomeChange = (event) => {
    setMonthlyIncome(event.target.value);
  }

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  }

  const handleJobTitleChange = (event) => {
    setJobTitle(event.target.value);
  }

  const handleEmploymentDurationChange = (event) => {
    setEmploymentDuration(event.target.value);
  }

  const handleMonthChange = (event) => {
    setEmpDur({
      ...empDur,
      month: event.target.value
    })
  }

  const handleYearChange = (event) => {
    setEmpDur({ ...empDur, year: event.target.value })
  }

  const handleAddressChange = (event) => {
    setHomeAddress({
      ...homeAddress,
      address: event.target.value
    })
  }

  const handleCityChange = (e) => {
    setHomeAddress({
      ...homeAddress,
      city: e.target.value
    })
  }

  const handleProvinceChange = (e) => {
    setHomeAddress({
      ...homeAddress,
      province: e.target.value
    })
  }

  const handlePostalChange = (e) => {
    setHomeAddress({
      ...homeAddress,
      postalCode: e.target.value
    })
  }

  const handleDuration = (event) => {
    setDuration(event.target.value);
  }

  const handleResidenceType = (event) => {
    setIsOwner(event.target.value)
  }

  const handleHouseRent = (event) => {
    setHoueRent(event.target.value)
  }

  const handleDOBChange = (event) => {
    setDateOfBirth(event.target.value)
  }

  const handleFnameChange = (event) => {
    setName({
      ...name,
      fname: event.target.value
    })
  }

  const handleLnameChange = (event) => {
    setName({
      ...name,
      lname: event.target.value
    })
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value)
  }

  const handleNext = () => {
      setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = () => {
    alert(` 
    ____Your Details____\n 
    Email : ${email} 
    Name : ${name.fname} ${name.lname}
    Address : ${homeAddress.address} ${homeAddress.city} ${homeAddress.province} ${homeAddress.postalCode} 
    Phone No : ${phone} 
  `)
  }

  return (
    <div className="container mx-auto px-4">
      <Stepper currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} handleSubmit={handleSubmit} handleVehicleTypeChange={handleVehicleTypeChange} vehicleType={vehicleType} handleCreditScoreChange={handleCreditScoreChange} creditScore={creditScore} handleDownPaymentChange={handleDownPaymentChange} downPayment={downPayment} handleBudgetChange={handleBudgetChange} budget={budget} handleTradeInChange={handleTradeInChange} tradeIn={tradeIn} handleEmploymentStatusChange={handleEmploymentStatusChange} employmentStatus={employmentStatus} handleMonthlyIncomeChange={handleMonthlyIncomeChange} handleCompanyNameChange={handleCompanyNameChange} handleJobTitleChange={handleJobTitleChange} handleEmploymentDurationChange={handleEmploymentDurationChange} employmentDuration={employmentDuration}
        monthlyIncome={monthlyIncome} companyName={companyName} jobTitle={jobTitle} handleVisibility={handleVisibility} isShown={isShown} isShownLong={isShownLong} empDur={empDur} homeAddress={homeAddress} handleMonthChange={handleMonthChange} handleYearChange={handleYearChange} handleAddressChange={handleAddressChange} handleCityChange={handleCityChange} handlePostalChange={handlePostalChange} handleProvinceChange={handleProvinceChange} handleDuration={handleDuration} duration={duration} handleResidenceType={handleResidenceType} isOwner={isOwner} handleHouseRent={handleHouseRent} houseRent={houseRent} dateOfBirth={dateOfBirth} handleDOBChange={handleDOBChange} handleFnameChange={handleFnameChange} handleLnameChange={handleLnameChange} name={name} handleEmailChange={handleEmailChange} email={email} handlePhoneChange={handlePhoneChange} phone={phone} />
    </div>
  );
};

const Stepper = ({ currentStep, handleNext, handlePrev, handleVehicleTypeChange, vehicleType, handleCreditScoreChange, creditScore, handleDownPaymentChange, downPayment, handleBudgetChange, budget, handleTradeInChange, tradeIn, handleEmploymentStatusChange, employmentStatus, handleMonthlyIncomeChange, handleCompanyNameChange, handleJobTitleChange, handleEmploymentDurationChange, monthlyIncome, companyName, jobTitle, handleVisibility, isShown, isShownLong, employmentDuration, empDur, handleMonthChange, handleYearChange, handleAddressChange, handleCityChange, handleProvinceChange, handlePostalChange, homeAddress, handleDuration, duration, handleResidenceType, isOwner, handleHouseRent, houseRent, handleDOBChange, dateOfBirth, handleFnameChange, handleLnameChange, name, handleEmailChange, email, handlePhoneChange, phone, handleSubmit }) => {



  const steps = [
    {
      id: 1,
      title: "Let's find some vehicles in your budget.",
      subtitle: "What are you looking for?",
      content: (
        <div className=' text-center'>
          <div className="flex flex-wrap w-full justify-evenly" onChange={handleVehicleTypeChange}>
            <label className="form-button text-center">
              <input
                type="radio"
                name="driver-type"
                value="Car"
                className="form-radio"
                checked={vehicleType === "Car"}
              />
              <span className='details'>
                <img src={car} />
                <span className="">Car</span>
              </span>
            </label>
            <label className="form-button text-center">
              <input
                type="radio"
                name="driver-type"
                value="Truck"
                className="form-radio"
                checked={vehicleType === "Truck"}
              />
              <span className='details'>
                <img src={truck} />
                <span className="ml-2">Truck</span>
              </span>
            </label>
            <label className="form-button text-center">
              <input
                type="radio"
                name="driver-type"
                value="SUV"
                className="form-radio"
                checked={vehicleType === "SUV"}
              />
              <span className='details'>
                <img src={suv} />
                <span className="ml-2">SUV</span>
              </span>
            </label>
            <label className="form-button text-center">
              <input
                type="radio"
                name="driver-type"
                value="Van"
                className="form-radio"
                checked={vehicleType === "Van"}
              />
              <span className='details'>
                <img src={van} />
                <span className="ml-2">Van</span>
              </span>
            </label>
          </div>
          <button
            type="button"
            className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
            duration-500'
            onClick={handleNext}
          >
            Next →
          </button>
        </div>
      ),
    },
    {
      id: 2,
      title: 'To see accurate payments,',
      subtitle: "What's your estimated credit score?",
      content: (
        <div>

          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2" onChange={handleCreditScoreChange}>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="credit-score"
                value="700+"
                className="form-radio"
                checked={creditScore === "700+"}
              />
              <span className='details'>
                <span className="">Over 700</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="credit-score"
                value="600 - 700"
                className="form-radio"
                checked={creditScore === "600 - 700"}
              />
              <span className='details'>
                <span className="">600 - 700</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="credit-score"
                value="500-600"
                className="form-radio"
                checked={creditScore === "500-600"}
              />
              <span className='details'>
                <span className="">500 - 600</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="credit-score"
                value="below 500"
                className="form-radio"
                checked={creditScore === "below 500"}
              />
              <span className='details'>
                <span className="">Below 500</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="credit-score"
                value="Current Bamkruptcy"
                className="form-radio"
                checked={creditScore === "Current Bamkruptcy"}
              />
              <span className='details'>
                <span className="">Current Bankruptcy</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="credit-score"
                value="Not Sure"
                className="form-radio"
                checked={creditScore === "Not Sure"}
              />
              <span className='details'>
                <span className="">Not Sure</span>
              </span>
            </label>

          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 3,
      title: 'How much could you put as a down payment?',
      content: (
        <div>

          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2" onChange={handleDownPaymentChange}>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="down-payment"
                value="0"
                className="form-radio"
                checked={downPayment === "0"}
              />
              <span className='details'>
                <span className="">$0</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="down-payment"
                value="$100 - $499"
                className="form-radio"
                checked={downPayment === "$100 - $499"}
              />
              <span className='details'>
                <span className="">$100 - $499</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="down-payment"
                value="$500 - $999"
                className="form-radio"
                checked={downPayment === "$500 - $999"}
              />
              <span className='details'>
                <span className="">$500 - $999</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="down-payment"
                value="$1000 - $1999"
                className="form-radio"
                checked={downPayment === "$1000 - $1999"}
              />
              <span className='details'>
                <span className="">$1000 - $1999</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="down-payment"
                value="$2000+"
                className="form-radio"
                checked={downPayment === "$2000+"}
              />
              <span className='details'>
                <span className="">$2000+</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="down-payment"
                value="Not Sure"
                className="form-radio"
                checked={downPayment === "Not Sure"}
              />
              <span className='details'>
                <span className="">Unsure</span>
              </span>
            </label>

          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      title: 'Great, I can work with that.',
      subtitle: "What's your budget?",
      content: (
        <div>

          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2" onChange={handleBudgetChange}>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="Less than $300 / month"
                className="form-radio"
                checked={budget === "Less than $300 / month"}
              />
              <span className='details'>
                <span className="">Less than $300 / month</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="$300 - $ 399 / month"
                className="form-radio"
                checked={budget === "$300 - $ 399 / month"}
              />
              <span className='details'>
                <span className="">$300 - $ 399 / month</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="$400 - $500 / month"
                className="form-radio"
                checked={budget === "$400 - $500 / month"}
              />
              <span className='details'>
                <span className="">$400 - $500 / month</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="Over $500"
                className="form-radio"
                checked={budget === "Over $500"}
              />
              <span className='details'>
                <span className="">Over $500</span>
              </span>
            </label>

          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 5,
      title: 'We pay top dollar for trade-ins.',
      subtitle: "Are you looking to trade in a vehicle?",
      content: (
        <div>

          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2" onChange={handleTradeInChange}>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="yes"
                className="form-radio"
                checked={tradeIn === "yes"}
              />
              <span className='details'>
                <span className="">Yes</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="no"
                className="form-radio"
                checked={tradeIn === "no"}
              />
              <span className='details'>
                <span className="">No</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="unsure"
                className="form-radio"
                checked={tradeIn === "unsure"}
              />
              <span className='details'>
                <span className="">Unsure</span>
              </span>
            </label>

          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 6,
      title: 'To see the best payment options,',
      subtitle: "What's your employment status?",
      content: (
        <div>

          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2" onChange={handleEmploymentStatusChange}>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="Employed"
                className="form-radio"
                checked={employmentStatus === "Employed"}
              />
              <span className='details'>
                <span className="">Employed</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="Self-Employed"
                className="form-radio"
                checked={employmentStatus === "Self-Employed"}
              />
              <span className='details'>
                <span className="">Self-Employed</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="Not Working"
                className="form-radio"
                checked={employmentStatus === "Not Working"}
              />
              <span className='details'>
                <span className="">I'm Not Working</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="Retired"
                className="form-radio"
                checked={employmentStatus === "Retired"}
              />
              <span className='details'>
                <span className="">Retired</span>
              </span>
            </label>

          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 7,
      title: 'And Your Total Monthly Income',
      subhead: "Before taxes & deductions",
      content: (
        <div>

          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2" onChange={handleMonthlyIncomeChange}>

            <input
              type="number"
              name="monthly-income"
              className="text-center border-2 rounded-xl text-2xl py-2 my-2"
              placeholder='0'
              value={monthlyIncome}
            />
          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 8,
      title: 'What do you do for work?',
      content: (
        <div>

          <div className="flex flex-wrap md:w-fit w-60 mx-auto mb-2">
            <label className="flex flex-col text-3xl lg:m-4 mx-auto" onChange={handleCompanyNameChange}>
              Company Name
              <input
                type="text"
                name="company-name"
                className="border-2 rounded-xl text-2xl p-2 my-2 md:w-96"
                value={companyName}
              />
            </label>

            <label className="flex flex-col text-3xl lg:m-4 mx-auto" onChange={handleJobTitleChange}>
              Job Title
              <input
                type="text"
                name="job-title"
                className="border-2 rounded-xl text-2xl p-2 my-2 md:w-96"
                value={jobTitle}
              />
            </label>

          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 9,
      title: 'And how long have you worked there?',
      content: (
        <div>
          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2" onChange={handleEmploymentDurationChange && handleVisibility}>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="month"
                value="0"
                className="form-radio"
                checked={employmentDuration === "0"}
              />
              <span className='details'>
                <span className="">Less than 1 Year</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="year"
                value="1"
                className="form-radio"
                checked={employmentDuration === "1"}
              />
              <span className='details'>
                <span className="">More than 1 Year</span>
              </span>
            </label>
            {isShown ? (
              <select name="month" value={empDur.month} className='w-full mt-8 text-xl p-2 border-2 rounded-md' onChange={handleMonthChange}>
                <option value="">Select months</option>
                <option value="1 Month">1 month</option>
                <option value="2 Months">2 months</option>
                <option value="3 Months">3 months</option>
                <option value="4 Months">4 months</option>
                <option value="5 Months">5 months</option>
                <option value="6 Months">6 months</option>
                <option value="7 Months">7 months</option>
                <option value="8 Months">8 months</option>
                <option value="9 Months">9 months</option>
                <option value="10 Months">10 months</option>
                <option value="11 Months">11 months</option>
              </select>
            ) : isShownLong ? (
              <div className='container'>
                <select name="year" value={empDur.year} className='mt-8 text-xl p-2 border-2 rounded-md mr-2' onChange={handleYearChange}>
                  <option value="">Select years</option>
                  <option value="1 year">1 year</option>
                  <option value="2 years">2 years</option>
                  <option value="3 years">3 years</option>
                  <option value="4 years">4 years</option>
                  <option value="5 years">5 years</option>
                  <option value="6 years">6 years</option>
                  <option value="7 years">7 years</option>
                  <option value="8 years">8 years</option>
                  <option value="9 years">9 years</option>
                  <option value="10 years">10 years</option>
                  <option value="10+ years">10+ years</option>
                </select>
                <select name="month" value={empDur.month} className='mt-8 text-xl p-2 border-2 rounded-md ml-2' onChange={handleMonthChange}>
                  <option value="">Select months</option>
                  <option value="1 Month">1 month</option>
                  <option value="2 Months">2 months</option>
                  <option value="3 Months">3 months</option>
                  <option value="4 Months">4 months</option>
                  <option value="5 Months">5 months</option>
                  <option value="6 Months">6 months</option>
                  <option value="7 Months">7 months</option>
                  <option value="8 Months">8 months</option>
                  <option value="9 Months">9 months</option>
                  <option value="10 Months">10 months</option>
                  <option value="11 Months">11 months</option>
                </select>
              </div>
            ) : null}
          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 10,
      title: "What's your home address?",
      subhead: "This helps me Find out the best deals in your area.",
      content: (
        <div>

          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2">
            <label className="text-center my-2"> Address Line 1
            </label>
            <input
              type="text"
              name="address"
              value={homeAddress.address}
              className="border-2 text-xl p-2"
              onChange={handleAddressChange}
            />
            <label className="text-center my-2"> City
            </label>
            <input
              type="text"
              name="city"
              value={homeAddress.city}
              className="border-2 text-xl p-2"
              onChange={handleCityChange}
            />
            <label className="text-center my-2"> Province
            </label>
            <select name="province" value={homeAddress.province} className="border-2 text-xl p-2" onChange={handleProvinceChange}>
              <option value=""></option>
              <option value="AB">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="MB">Manitoba</option>
              <option value="NB">New Brunswick</option>
              <option value="NL">Newfoundland and Labrador</option>
              <option value="NS">Nova Scotia</option>
              <option value="ON">Ontario</option>
              <option value="PE">Prince Edward Island</option>
              <option value="QC">Quebec</option>
              <option value="SK">Saskatchewan</option>
              <option value="NT">Northwest Territories</option>
              <option value="NU">Nunavut</option>
              <option value="YT">Yukon</option>
            </select>
            <label className="text-center my-2"> PostalCode
            </label>
            <input
              type="text"
              name="postalcode"
              value={homeAddress.postalCode}
              className="border-2 text-xl p-2"
              onChange={handlePostalChange}
            />
          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 12,
      title: 'How long have you Lived at this address?',
      content: (
        <div>

          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2" onChange={handleDuration}>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="Less than 1 Year"
                className="form-radio"
                checked={duration === "Less than 1 Year"}
              />
              <span className='details'>
                <span className="">Less than 1 Year</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="More than 1 Year"
                className="form-radio"
                checked={duration === "More than 1 Year"}
              />
              <span className='details'>
                <span className="">More than 1 Year</span>
              </span>
            </label>
          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id:13,
      title: 'Do you rent or Own Your home?',
      content: (
        <div>

          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2">
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="Rent"
                className="form-radio"
                onChange={handleResidenceType}
                checked={isOwner === "Rent"}
              />
              <span className='details'>
                <span className="">Rent</span>
              </span>
            </label>
            <label className="form-button text-center my-2">
              <input
                type="radio"
                name="driver-type"
                value="Own"
                className="form-radio"
                onChange={handleResidenceType}
                checked={isOwner === "Own"}
              />
              <span className='details'>
                <span className="">Own</span>
              </span>
            </label>
            <label className='text-xl font-semibold pt-4 text-center'>Monthly Paymenyt</label>
            <input
              type="number"
              name="house-pay"
              className='border-2 p-2 text-xl text-center'
              value={houseRent}
              onChange={handleHouseRent}
            />
          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id:14,
      title: 'What is your Date of Birth?',
      subhead: "DD/MM/YYYY",
      content: (
        <div>

          <div className="flex flex-row md:w-96 w-60 mx-auto mb-2 justify-between" onChange={handleDOBChange}>
            <input
              type='date'
              className='mx-auto border-2 p-2 text-xl'
              name="DOB"
              value={dateOfBirth} />
          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 15,
      title: "Lastly, What's your name?",
      content: (
        <div>

          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2">
            <label className="text-center my-2">First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={name.fname}
              className="border-2 p-2 mr-2"
              onChange={handleFnameChange}
            />
            <label className="text-center my-2">Last Name
            </label>
            <input
              type="text"
              name="firstName"
              value={name.lname}
              className="border-2 p-2 mr-2"
              onChange={handleLnameChange}
            />
          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleNext}
            >
              Next →
            </button>
          </div>
        </div>
      ),
    },
    {
      id: 16,
      title: 'Get Your Offers Now',
      content: (
        <div>
          <div className="flex flex-col md:w-96 w-60 mx-auto mb-2">
            <label className="text-center my-2">Email
            </label>
            <input
              type="email"
              name="Email"
              value={email}
              className="border-2 p-2 mr-2"
              onChange={handleEmailChange}
            />
            <label className="text-center my-2">Phone
            </label>
            <input
              type="tel"
              name="Phone"
              value={phone}
              minLength={10}
              maxLength={10}
              className="border-2 p-2 mr-2"
              onChange={handlePhoneChange}
            />
          </div>
          <div className="flex items-center justify-between md:w-96 mx-auto">
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handlePrev}
            >
              ← Previous
            </button>
            <button
              type="button"
              className='mt-4 bg-blue-500 text-white font-[Poppins] lg:text-2xl text-lg py-2 px-6 rounded-3xl hover:text-blue-500 hover:bg-white border-2 border-transparent hover:border-blue-500
              duration-500'
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      ),
    },
    // Add more steps here
  ];

  return (
    <div className="stepper mt-4">
      <div className='flex-auto border-t-4 transition duration-500 ease-in-out'></div>
      <div className="stepper-header mt-8">
        <h2 className="stepper-title text-4xl">{steps[currentStep - 1].title}</h2>
        <h2 className="stepper-title text-4xl">{steps[currentStep - 1].subtitle}</h2>
        <h2 className="stepper-title text-lg">{steps[currentStep - 1].subhead}</h2>
      </div>
      <div className="stepper-content">
        {steps[currentStep - 1].content}
      </div>
    </div>
  );
};

export default FinanceForm;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import ContactUs from './pages/ContactUs';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import ServicesPage from './pages/ServicesPage';
import Cars from './pages/Cars'
import Sell from './pages/SellOrTrade'
import Finance from './pages/Finance';
import CarFinanceCalculator from './pages/FinanceCalculator';
import About from './pages/About';
import FinanceForm from './components/Finance/FinanceForm';



function App() {return (
    <Router>
      <div className="bg-white">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/financecal" element={<CarFinanceCalculator />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/cars" element={<Cars />} />
          <Route path="/Sell" element={<Sell />} />
          <Route path="/about" element={<About />} />
          <Route path="/financeform" element={<FinanceForm />} />
        </Routes>
      </div>
    </Router>
  );
}


export default App;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import bannerimg from '../../images/banner.jpg';

class Reviews extends Component {
    
    render() {
        return (
            <div className="pt-16 flex flex-col">
                <div className='md:w-8/12 mx-auto text-center'>
                    <h2 className="text-4xl font-bold mb-8">Reviews</h2>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center bg-white rounded-xl'>
                        <div className='flex flex-col flex-1'>
                            <img className='lg:m-0 m-4 bg-no-repeat bg-cover bg-center rounded-2xl my-auto' src={bannerimg} />
                        </div>
                        <div className='flex flex-col flex-1 text-left h-full my-auto lg:pt-0 pt-8'>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="md:text-xl mb-4 text-lg font-semibold">Shop Online</h2>
                                <p className='mb-4'>Discover hundreds of vehicles to fit every budget and style.</p>
                                <hr className=' border-gray-400'/>
                            </div>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="md:text-xl mb-4 text-lg font-semibold">Delivery to Your Door</h2>
                                <p className='mb-4'>Your chosen car arrives at your doorstep within days.</p>
                                <hr className=' border-gray-400'/>
                            </div>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="md:text-xl mb-4 text-lg font-semibold">Risk-Free Returns</h2>
                                <p className='mb-4'>If it's not right for you, we'll take it back. Applies to select options.</p>
                                <hr className=' border-gray-400'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default Reviews
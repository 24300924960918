import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dreamcar from '../../images/dreamcar.jpg'

class Banner extends Component {
    
    render() {
        return (
            <div className="pt-16 flex flex-col">
                    <h2 className="text-4xl font-bold mb-8 mx-auto z-50 text-center">Buy Your <span className='text-blue-500'>Dream Car<br/><span className=' text-3xl text-black'>How it Works?</span></span></h2>
                <div className='md:w-8/12 mx-auto text-center border-2 rounded-2xl'>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center bg-blue-500 text-white rounded-2xl'>
                        
                        <div className='flex flex-col flex-1 text-left h-full my-auto lg:pt-0 pt-8'>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="md:text-xl mb-2 text-lg font-semibold">Step 1: Get Pre-Approved</h2>
                                <p className='mb-4'>Fill out our quick online application to discover the amount you can be pre-approved for in just minutes.</p>
                                <hr className=' border-white'/>
                            </div>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="md:text-xl mb-2 text-lg font-semibold">Step 2: Explore Available Vehicles</h2>
                                <p className='mb-4'>We'll link you with a nearby dealership that will present you with cars within your qualifying range, streamlining the process and alleviating any potential stress.</p>
                                <hr className=' border-white'/>
                            </div>
                            <div className='mx-auto w-4/5 mb-4'>
                                <h2 className="md:text-xl mb-2 text-lg font-semibold">Step 3: Select Your Desired Car</h2>
                                <p className='mb-4'>Pick the vehicle that suits your needs, and it's yours! Typically, we can have you driving your new car in as little as 48 hours.</p>
                                <hr className=' border-white'/>
                            </div>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <img className='lg:m-0 m-4 bg-no-repeat bg-cover bg-center rounded-2xl my-auto' src={dreamcar} />
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default Banner
import React from 'react'
import { Link } from 'react-router-dom'
import Logo_dark from '../images/Logo2.png'
import Logo_light from '../images/Logo1.png'

const NavigationBar = () => {
  const [showMenu, setShowMenu] = React.useState(false);

  return (
    <nav className=" bg-white shadow-lg">
      <div className="mx-auto px-12 py-6">
        <div className="flex justify-between">
          <div className='flex items-center'>
            <Link to="/" className="flex items-center text-gray-800 font-bold text-lg">
              <img src={Logo_light} alt="Logo" className="h-12 mr-2" />              
            </Link>
          </div>
          <div className=" text-xl hidden md:flex items-center space-x-3">
            {/* <Link to="/Sell" className="py-2 px-2 font-medium text-blue-500 rounded hover:bg-blue-500 hover:text-white transition duration-300">
              Sell / Trade
            </Link> */}
            <Link to="/finance" className="py-2 px-2 font-medium text-blue-500 rounded hover:bg-blue-500 hover:text-white transition duration-300">
              Finance
            </Link>
            <Link to="/financecal" className="py-2 px-2 font-medium text-blue-500 rounded hover:bg-blue-500 hover:text-white transition duration-300">
              Finance Calculator
            </Link>
            <Link to="/about" className="py-2 px-2 font-medium text-blue-500 rounded hover:bg-blue-500 hover:text-white transition duration-300">
              About
            </Link>
            <Link to="/contact" className="py-2 px-2 font-medium text-blue-500 rounded hover:bg-blue-500 hover:text-white transition duration-300">
              Contact
            </Link>
          </div>
          <div className="md:hidden flex items-center">
            <button className="outline-none mobile-menu-button" onClick={() => setShowMenu(!showMenu)}>
              <svg className="w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {showMenu && (
        <div className="mobile-menu mt-4">
          <ul className="">
            <li className="active"><Link to="/" className="block text-sm px-2 py-4 text-white bg-blue-500 font-semibold">Home</Link></li>
            <li><Link to="/services" className="block text-sm px-2 py-4 hover:bg-blue-500 transition duration-300">Services</Link></li>
            <li><Link to="/about" className="block text-sm px-2 py-4 hover:bg-blue-500 transition duration-300">About</Link></li>
            <li><Link to="/contact" className="block text-sm px-2 py-4 hover:bg-blue-500 transition duration-300">Contact</Link></li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default NavigationBar;
import React from 'react';
import Hero from '../components/Contact/hero';
import bannerimg from '../images/banner.jpg';
import Form from '../components/Contact/form';
import Info from '../components/Contact/info';
import Faq from '../components/Contact/Faq';



const Contact = () => {
  return (
    <div className="container mx-auto">
      <Hero />
      <div className="pt-16 flex flex-col">
        <div className='md:w-10/12 mx-auto text-center'>
          <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center'>
            <div className='flex flex-col flex-1 text-left justify-between lg:pt-0 pt-8'>
              <Info/>
            </div>
            <div className='flex flex-col flex-1'>
              <Form />
            </div>
          </div>
        </div>
      </div>
      <Faq/>
    </div>
  );
};

export default Contact;